<script>
    import Vue from 'vue';
    import BaseVue from '@frontend/Base.vue';
    import Gen from '../helper/Gen';
    Vue.component("Header",()=>import("@frontend/part/Header2.vue"))

    export default {
        extends: BaseVue,
        data() {
            return {
            	data:[],
            	name_tag:'',
            	filter: {skip:0},
            	loadingLoadmore: false,
            	page404:false,
            }
        },
        computed:{
			myParams(){
				var query = Gen.clone(this.$route.query)
				if(this.$route.query.tag) query.tag = this.$route.query.tag
				return query
			}
		},
        async mounted() {
        	await this.ready();
        		Vue.component("Header",()=>import("@frontend/part/Header2.vue"))
            this.$set(this.$root, 'page', this);
            if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
            setTimeout(() => {
            	$('[data-toggle="tooltip"]').tooltip();
                SEMICOLON.documentOnReady.init()
            }, 300)
            $(document).ready(() => {
                AOS.init()
            })
            this.apiGetCustomerActivitiesList()
        },
        methods: {
        	apiGetCustomerActivitiesList(callBack=null){
        		Gen.apirest('/detail-tags/'+this.$route.params.slug, this.myParams, (err, resp)=>{
              		if(err) console.log(err)
              		if(resp.code==404)return this.page404=true
              		if(callBack) return callBack(resp)
              		this.data=resp.data
              		this.name_tag=resp.name_tag
              		this.$root.topProgress.done()
	          	})
        	},
        	onPaging(page = 1) {
		      	if ($(".page-item.active").text() == page) return;
		      	var query = Object.assign(Gen.clone(this.$route.query), {
		        	page: page
		      	})
		      	this.$router.push({
		        	name: this.$route.name,
		        	query: query
		      	})
		    },
        },
        watch:{
        	"$route.params"(v){
        	 	console.log(v)
        	},
        	$route() {
        		this.apiGetCustomerActivitiesList()
        	}
        },
    };
</script>
<template>
  	<div Content>
  		<Component404 v-if="page404"></Component404>
		<div v-else>
	  		<Header></Header>
			<section id="content" class="overflow-none">
				<section id="page-title" class="page-title-mini">
				    <div class="container clearfix">
				        <h1 class="d-none d-md-block">&nbsp;</h1>
				        <ol class="breadcrumb">
				            <li class="breadcrumb-item"><router-link :to="{name:'index'}">{{ web.mn_home }}</router-link></li>
				            <li class="breadcrumb-item active" aria-current="page">{{ name_tag }}</li>
				        </ol>
				    </div>
				</section>
				<div class="content-wrap">
					<section class="section nobottommargin notopmargin">
						<div class="container" v-if="data.length==0">
							<div class="row justify-content-center">
								<div class="col-lg-12">
									<div class="detail_article">
										<h2 class="text-center">Data tidak ditemukan.</h2>
									</div>
								</div>
							</div>
						</div>
						<div class="container" v-else>
							<div class="row">
								<div v-for="(v,k) in data.data" class="col-md-6 col-lg-4">
									<div class="front-news">
										<div class="fn-img">
											<router-link :to="{name:'DetailCustomerActivities',params:{slug:v.ap_slug}}">
												<VImg v-if="!isMobile" :src="uploader(v.ap_photo,'400')" :alt="v.ap_title" :title="v.ap_title"></VImg>
												<VImg v-else :src="uploader(v.ap_photo,'330')" :alt="v.ap_title" :title="v.ap_title"></VImg>
											</router-link>
										</div>
										<div class="fn-desc min_height_132">
											<h4 class="fnd-title" v-if="v.length_title>'6'">
												<router-link v-if="v.ap_cat==1" :to="{name:'JurnalDetail',params:{slug:v.ap_slug}}" data-toggle="tooltip" :title="v.ap_title">{{v.ap_title.limitWord(6)}}</router-link>
												<router-link v-if="v.ap_cat==2" :to="{name:'ArticleDetail',params:{slug:v.ap_slug}}" data-toggle="tooltip" :title="v.ap_title">{{v.ap_title.limitWord(6)}}</router-link>
												<router-link v-if="v.ap_cat==3" :to="{name:'DetailCustomerActivities',params:{slug:v.ap_slug}}">{{v.ap_title.limitWord(6)}}</router-link>
											</h4>
											<h4 class="fnd-title" v-else>
												<router-link v-if="v.ap_cat==1" :to="{name:'JurnalDetail',params:{slug:v.ap_slug}}">{{ v.ap_title }}</router-link>
												<router-link v-if="v.ap_cat==2" :to="{name:'ArticleDetail',params:{slug:v.ap_slug}}">{{ v.ap_title }}</router-link>
												<router-link v-if="v.ap_cat==3" :to="{name:'DetailCustomerActivities',params:{slug:v.ap_slug}}">{{v.ap_title.limitWord(6)}}</router-link>
											</h4>
											
											<h5 class="fnd-name" v-if="v.length_name>'15'" data-toggle="tooltip" :title="v.ap_author_name">
												<i class="icon-user21"></i>
												{{v.ap_author_name.limitChar(15)}}
											</h5>
											<h5 class="fnd-name" v-else>
												<i class="icon-user21"></i>
												{{v.ap_author_name}}
											</h5>

											<span class="fnd-date">
												<i class="icon-calendar21"></i>
												{{(v.ap_publish_date).dates('format')}}
											</span>
										</div>
									</div>
								</div>
							</div>
							<Pagination :data="data" :limit="9" @pagination-change-page="onPaging"></Pagination>
                			<LoadingSpinner v-if="data===false" light></LoadingSpinner>
						</div>
					</section>
				</div>
			</section>
		</div>
  	</div>
</template>